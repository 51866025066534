
  import { personOutline, lockClosedOutline, eyeOutline, eyeOffOutline, arrowBackCircleOutline } from 'ionicons/icons';
  import { IonTitle, IonToolbar, IonHeader, IonCol, IonIcon, IonGrid, IonContent, IonItem, IonButton, IonInput,IonCheckbox,IonLabel, IonList, IonRow, IonPage, IonText, IonNote  } from '@ionic/vue';
  import { defineComponent } from 'vue';

  import axios from "axios";
  export default defineComponent({
    components: { IonTitle, IonToolbar, IonHeader, IonCol, IonIcon, IonGrid, IonContent, IonItem, IonButton, IonInput,IonCheckbox,IonLabel, IonList, IonRow, IonPage, IonText, IonNote
  },
  data(){
    return{
      disabledErrors: true,
      vshowPassword: false,
      verror: {
        password: ' ',
        email: ' ',
        global: ''
      },
      formData: {
        email: '',
        password: ''
      }
    }
  },
  setup() {
    return { personOutline, lockClosedOutline, eyeOutline, eyeOffOutline, arrowBackCircleOutline };
  },
  mounted:function(){
    //
  },
  methods:{
    showPassword: function() {
      this.vshowPassword = !this.vshowPassword;
    },
    validateEmail: function(event:string){
      var email = event
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(email == ''){
        this.verror.email = 'El correo es requerido'
      }else if(!regex.test(email)){
        this.verror.email = 'Ingrese un correo valido'
      }else{
        this.verror.email = ""
      }
      if(this.verror.password=='' && this.verror.email==""){
              this.disabledErrors= false
      }else{
              this.disabledErrors=true
      }
    },
    validatePassword: function(event:string){
      var password = event
      const regex =/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,12})/
      if(password == ''){
        this.verror.password = 'La contraseña es requerida'
      }else if(password.length < 6 ){
        this.verror.password = 'Debe contener mas de 5 caracteres'
      }else if(!regex.test(password)){
        this.verror.password = "El formato de contraseña es invalido"
      }else{
        this.verror.password = ""
      }
      if(this.verror.password=='' && this.verror.email==""){
              this.disabledErrors= false
      }else{
              this.disabledErrors=true
      }
    },
    handleLogin: function() {
      axios.post('http://alcaldiamarcano.biodarks.com/backend/public/index.php/api/user/login', this.formData).then(response => {
        console.log('todo ok');
        }).catch(error => this.verror.global = error.response.data.errors.global[0]); // credentials didn't match
      this.verror.email = '';
      this.verror.password = '';
      this.formData.password = '';
      this.verror.global = '';
    },


  },
});
